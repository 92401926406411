import React, {Component} from 'react'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {recordDisplay} from 'utils'
import styles from 'employees/Invite.scss'
import {actionCreators} from 'actions'
import api from 'api'
import {getGroups} from 'employees/form/EmploymentInfomation'
import {bindActionCreators} from 'redux'
import Button from 'jbc-front/components/Button'
import {getState} from 'utils'
import {getCurrentQueryFromLocation, fetchEmployees, getSavedDisplayEmployeeLimit} from 'employees/list/utils'
import PaginatorWithResult from 'employees/list/PaginatorWithResult'
import SearchForm from 'employees/list/SearchForm'
import SortableTh from 'employees/list/SortableTh'
import {Hint} from 'employees/Invite'
import {TextField} from 'jbc-front/components/Form'
import {reduxForm} from 'redux-form'
import {staffCode} from 'validators'
import Modal from 'jbc-front/components/CommonModal'
import {Redirect} from 'react-router-dom'
import {asyncError} from 'store/actions/asyncError'

export const invitationStatuses = {
  not_invited: '未招待',
  invited: '招待済み',
  registered: '登録済み',
  only_enrollment_input: '未招待',
}

const StaffCodeForm = reduxForm({
  form: 'stuffCodeInput',
  enableReinitialize: true,
  onSubmit: async (values, dispatch, {employeeId, hideModal}) => {
    const {
      auth: {token},
    } = await getState(dispatch)
    try {
      await api.createWithAuth(token).employees.update(employeeId, values)
      hideModal(true)
    } catch (err) {
      dispatch(asyncError(err))
    }
  },
})(({handleSubmit, name, hideModal, submitting}) => (
  <React.Fragment>
    <Modal.Header hideModal={hideModal}>スタッフコードの登録</Modal.Header>
    <Modal.Body>
      <form onSubmit={handleSubmit}>
        <div className="form_box_main">
          <TextField name="staff_code" label={`${name}さんのスタッフコード`} validate={staffCode} />
        </div>
      </form>
    </Modal.Body>
    <Modal.Footer>
      <Modal.Buttons>
        <Button onClick={hideModal}>キャンセル</Button>
        <Button primary onClick={handleSubmit} disabled={submitting}>
          保存
        </Button>
      </Modal.Buttons>
    </Modal.Footer>
  </React.Fragment>
))

export const StaffModal = ({isOpen, hideModal, ...rest}) => (
  <Modal isOpen={isOpen} hideModal={hideModal}>
    <StaffCodeForm hideModal={hideModal} {...rest} />
  </Modal>
)

class Row extends Component {
  state = {
    showHint: false,
    hint: null,
  }
  handleClick = () => {
    const {disabledMessage} = this.props
    if (disabledMessage) {
      this.setState({
        showHint: true,
        hint: disabledMessage,
      })
    } else {
      this.props.onClick(this.props.employee)
    }
  }
  handleInputClick = (e) => {
    e.stopPropagation()
    const {employee, onInputClick} = this.props
    onInputClick(employee.id, employee.display_last_name, {staff_code: employee.staff_code})
  }

  hideHint = () => {
    this.setState({
      showHint: false,
    })
  }

  render() {
    const {employee, selected, disabledMessage} = this.props
    const selectedClass = selected ? 'm-table-checkbox-on' : 'm-table-checkbox-off'
    const disabledClass = disabledMessage ? ' u-cur-notallowed' : ' u-cur-pointer'

    return (
      <tr>
        <td onClickCapture={this.handleClick} className={selectedClass + disabledClass}>
          {this.state.showHint && <Hint onClick={this.hideHint}>{this.state.hint}</Hint>}
          <input
            type="checkbox"
            checked={selected}
            className={disabledClass ? 'u-cur-notallowed' : 'u-cur-pointer'}
            readOnly
          />
        </td>
        <td className={styles.staffcodeColumn} onClick={this.handleInputClick}>
          <a className="u-txt-link">{employee.staff_code || '未入力'}</a>
        </td>
        <td className={styles.nameColumn}>{recordDisplay.fullName(employee)}</td>
        <td className={styles.employmentStatusColumn}>
          {recordDisplay(_.get(employee, 'personnel_history.employment_type_name'))}
        </td>
        <td className={styles.groupColumn}>
          {recordDisplay(
            !_.isEmpty(getGroups(employee)) &&
              getGroups(employee).map((group, index) => (
                <span key={index}>
                  {group}
                  <br />
                </span>
              ))
          )}
        </td>
        <td className={styles.positionColumn}>{recordDisplay(_.get(employee, 'personnel_history.position_name'))}</td>
        <td className={styles.dateColumn}>
          {recordDisplay(employee.joined_at && employee.joined_at.replace(/-/g, '/'))}
        </td>
      </tr>
    )
  }
}

const selectByAll = (employee) => !!employee.staff_code

const isSelected = (employee, selected) =>
  selected[employee.id] === undefined
    ? employee.staff_code && employee.syncs_with_payroll
    : employee.staff_code && selected[employee.id]

const isAllSelected = (employees, selected) =>
  employees.some((employee) => isSelected(employee, selected)) &&
  employees.every((employee) => isSelected(employee, selected) || !selectByAll(employee))

const additionalParams = {embed: ['personnel_history']}

class List extends Component {
  componentDidMount() {
    this.props.clearSelected()
  }

  state = {
    type: null,
    employeeId: null,
    isOpen: false,
    name: '',
    showConfirmModal: false,
    initialValues: {},
    submitting: false,
  }

  handleRowClick = (employee) => {
    const {changeMulti, selected} = this.props
    changeMulti([
      {
        [employee.id]: selected[employee.id] === undefined ? !employee.syncs_with_payroll : !selected[employee.id],
      },
    ])
  }

  saveTargets = async () => {
    const {token, dispatch, selected} = this.props
    try {
      this.setState({submitting: true})
      await api.createWithAuth(token).employees.payroll.updateTargets({selected})
      dispatch(push('/employees'))
    } catch (err) {
      dispatch(asyncError(err))
      this.setState({submitting: false})
    }
  }

  disabledMessage(employee) {
    if (!employee.staff_code) {
      return 'スタッフコードを入力してください'
    }
    return null
  }

  handleSelectAllClick = () => {
    const {employees, selected, changeMulti} = this.props
    const allSelected = isAllSelected(employees, selected)
    if (allSelected) {
      changeMulti(
        employees.filter((employee) => isSelected(employee, selected)).map((employee) => ({[employee.id]: false}))
      )
    } else {
      changeMulti(
        employees
          .filter(selectByAll)
          .filter((employee) => !isSelected(employee, selected))
          .map((employee) => ({[employee.id]: true}))
      )
    }
  }

  componentWillUnmount() {
    this.props.clearSelected()
  }

  hideModal = (loadData = false) => {
    this.setState({isOpen: false})
    if (loadData) {
      const {location, fetchEmployees, user} = this.props
      fetchEmployees(getCurrentQueryFromLocation(location, user))
    }
  }

  handleInputClick = (employeeId, name, initialValues = {}) => {
    this.setState({
      employeeId,
      isOpen: true,
      name,
      initialValues,
    })
  }

  render() {
    const {
      user,
      employees,
      selected,
      location: {pathname},
      client,
    } = this.props
    const allSelected = isAllSelected(employees, selected)
    const allClickClass = allSelected ? 'm-table-checkbox-on' : 'm-table-checkbox-off'

    return (
      <div>
        {client.plan_type === 'free_plan' && <Redirect to="/employees" />}
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">従業員の給与連携設定</h1>
          <p className="m-title-main-note">
            ジョブカン給与計算と連携する従業員を設定します。
            <br />
            設定しないとジョブカン給与計算と情報を連携されません。
          </p>
        </div>
        <div className="l-contents-wrap">
          <div className="l-wrap-xl">
            <SearchForm additionalParams={additionalParams} />

            <div className="l-overflow-scroll">
              <table className="m-table-list m-table-fixed">
                <thead>
                  <tr>
                    <th
                      onClick={this.handleSelectAllClick}
                      className={allClickClass + ' m-table-list-check u-cur-pointer'}
                    >
                      <input type="checkbox" readOnly checked={allSelected} />
                    </th>
                    <SortableTh field="staff_code" globalClassName={styles.staffcodeColumn}>
                      スタッフコード
                    </SortableTh>
                    <SortableTh field="full_name_kana" globalClassName={styles.nameColumn}>
                      氏名
                    </SortableTh>
                    <SortableTh field="employment_type" globalClassName={styles.employmentStatusColumn}>
                      雇用形態
                    </SortableTh>
                    <SortableTh field="group" globalClassName={styles.groupColumn}>
                      グループ
                    </SortableTh>
                    <SortableTh field="position" globalClassName={styles.positionColumn}>
                      役職
                    </SortableTh>
                    <SortableTh field="joined_at" globalClassName={styles.dateColumn}>
                      入社日
                    </SortableTh>
                  </tr>
                </thead>
                <tbody>
                  {employees.map((employee) => (
                    <Row
                      key={employee.id}
                      onClick={this.handleRowClick}
                      onInputClick={this.handleInputClick}
                      selected={
                        selected[employee.id] === undefined
                          ? employee.staff_code && employee.syncs_with_payroll
                          : employee.staff_code && selected[employee.id]
                      }
                      {...{employee}}
                      disabledMessage={this.disabledMessage(employee)}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <PaginatorWithResult limit={getSavedDisplayEmployeeLimit(pathname, user)} />
          </div>
          <div className="u-mt20 u-ta-c">
            <Button primary onClick={this.saveTargets} disabled={this.state.submitting || _.isEmpty(selected)}>
              対象者を保存する
            </Button>
          </div>
          <StaffModal hideModal={this.hideModal} {...this.state} />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    employees: state.employees.list.data,
    count: state.employees.list.count,
    token: state.auth.token,
    query: state.employees.query,
    selected: state.employees.selected,
    location: state.router.location,
    user: state.session.currentUser,
    client: state.client.current,
  }),
  (dispatch) => ({
    fetchEmployees(query) {
      dispatch(fetchEmployees(query, additionalParams))
    },
    ...bindActionCreators(
      _.pick(actionCreators.employees, ['toogleSelected', 'clearSelected', 'changeMulti']),
      dispatch
    ),
    dispatch,
  })
)(List)
