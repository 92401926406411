import React, {Component, useState, useRef, useEffect} from 'react'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import {actionCreators} from 'actions'
import api from 'api'
import Modal from 'jbc-front/components/CommonModal'
import {reduxForm, submit} from 'redux-form'
import {TextField, DateField} from 'jbc-front/components/Form'
import * as validators from 'validators'
import _ from 'lodash'
import styles from 'procedures/enroll/EmployeesList.scss'
import {bindActionCreators} from 'redux'
import {recordDisplay, getState} from 'utils'
import {getGroups} from 'employees/form/EmploymentInfomation'
import {getCurrentQueryFromLocation, fetchEmployees, getSavedDisplayEmployeeLimit} from 'employees/list/utils'
import PaginatorWithResult from 'employees/list/PaginatorWithResult'
import SearchForm from 'employees/list/SearchForm'
import SortableTh from 'employees/list/SortableTh'
import Button from 'jbc-front/components/Button'
import classNames from 'classnames'
import {useHasBranchOffice} from 'hooks/useHasBranchOffice'
import {asyncError} from 'store/actions/asyncError'

const EMAIL = 1
const WILL_ENROLL_ON = 2

const emailFormRender = ({handleSubmit, name}) => (
  <form onSubmit={handleSubmit}>
    <TextField name="email" label={`${name}さんのメールアドレス`} validate={validators.email} />
  </form>
)

const connectForm = connect(null, (dispatch, ownProps) => ({
  async onSubmit(values) {
    const {
      auth: {token},
    } = await getState(dispatch)
    try {
      await api.createWithAuth(token).employees.update(ownProps.employeeId, values)
      ownProps.hideModal(true)
    } catch (err) {
      dispatch(asyncError(err))
    }
  },
}))

const EmailForm = connectForm(
  reduxForm({
    form: 'enrollEmailInput',
    enableReinitialize: true,
  })(emailFormRender)
)

const willEnrollOnFormRender = ({handleSubmit, name}) => (
  <form onSubmit={handleSubmit}>
    <div className="form_box_main">
      <DateField name="joined_at" label={`${name}さんの入社予定日`} />
    </div>
  </form>
)

const WillEnrollOnForm = connectForm(
  reduxForm({
    form: 'enrollWillEnrollOnFormInput',
    enableReinitialize: true,
  })(willEnrollOnFormRender)
)

class InputModal extends Component {
  handleModalSubmit = () => {
    this.props.submit()
  }

  render() {
    const {type, employeeId, show, hideModal, name, initialValues} = this.props
    const Form = type === EMAIL ? EmailForm : WillEnrollOnForm
    return (
      <Modal isOpen={show} hideModal={hideModal}>
        <Modal.Header hideModal={hideModal}>メールアドレスの登録</Modal.Header>
        <Modal.Body style={{overflow: 'visible'}}>
          <Form employeeId={employeeId} hideModal={hideModal} name={name} initialValues={initialValues} />
        </Modal.Body>
        <Modal.Footer>
          <Modal.Buttons>
            <Button onClick={hideModal}>キャンセル</Button>
            <Button primary onClick={this.handleModalSubmit}>
              保存
            </Button>
          </Modal.Buttons>
        </Modal.Footer>
      </Modal>
    )
  }
}

const ConnectedInputModal = connect(undefined, (dispatch, ownProps) => {
  const form = ownProps.type === EMAIL ? 'enrollEmailInput' : 'enrollWillEnrollOnFormInput'
  return {
    submit: () => dispatch(submit(form)),
  }
})(InputModal)

const ShouldInput = ({text = '未入力', type, onClick}) => {
  const handleClick = (e) => {
    onClick(type)
    e.stopPropagation()
  }
  return (
    <a onClick={handleClick} className="u-txt-link">
      {text}
    </a>
  )
}

const isProcessing = (employee) =>
  ['none', 'applying', 'rejected'].includes(_.get(employee, 'detail_input_request.status'))

const TdWithHint = ({type, text, disabledMessage, selected, onClick}) => {
  const [isOpen, setIsOpen] = useState(false)
  const container = useRef(null)
  const shapeWidth = 15
  const childWidth = _.get(container, 'current.clientWidth')
  const selectedClass = selected ? `m-table-${type}-on` : `m-table-${type}-off`
  const disabledClass = disabledMessage ? 'u-cur-notallowed' : 'u-cur-pointer'
  const handleClickOutSide = (event) =>
    disabledMessage && container && !container.current.contains(event.target) && setIsOpen(false)
  const handleClickTd = () => (disabledMessage ? setIsOpen(!isOpen) : onClick())
  useEffect(() => {
    document.addEventListener('click', handleClickOutSide)
    return () => document.removeEventListener('click', handleClickOutSide)
  }, [handleClickOutSide])
  return (
    <td onClick={handleClickTd} ref={container} className={classNames(selectedClass, disabledClass, styles.checkBox)}>
      {isOpen && (
        <>
          <div
            className={styles.shape}
            style={{
              left: childWidth ? `${childWidth / 2 - shapeWidth / 2}px` : '0',
            }}
          />
          <div className={styles.message}>{text}</div>
        </>
      )}
    </td>
  )
}

class Row extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInfo: false,
    }
  }
  handleClick = () => {
    const {disabledMessage} = this.props
    if (disabledMessage) {
      this.setState({showInfo: true})
    } else {
      this.props.onClick(this.props.employee.id)
    }
  }

  handleInputClick = (type) => {
    const {employee} = this.props
    if (type === EMAIL) {
      this.props.onInputClick(employee.id, type, employee.last_name, {email: employee.email})
    } else {
      this.props.onInputClick(employee.id, type, employee.last_name)
    }
  }

  hideInfo = () => {
    this.setState({
      showInfo: false,
    })
  }

  render() {
    const {employee, selected, type, isRequest, isDirectRequest, disabledMessage, hasBranchOffice} = this.props
    return (
      <tr>
        <TdWithHint
          type={type}
          text={disabledMessage}
          onClick={this.handleClick}
          selected={selected}
          disabledMessage={disabledMessage}
        />
        {isRequest && (
          <td className={styles.requestStatus}>{employee.received_request_status_count > 0 ? '依頼済み' : '未依頼'}</td>
        )}
        {isDirectRequest && <td className={styles.requestStatus}>{isProcessing(employee) ? '更新中' : '未依頼'}</td>}
        <td className={styles.staffcode}>{recordDisplay(employee.staff_code)}</td>
        <td className={styles.nameColumn}>{recordDisplay.fullName(employee)}</td>
        <td className={styles.mailAddress}>
          {isRequest ? (
            <ShouldInput onClick={this.handleInputClick} type={EMAIL} text={employee.email || undefined} />
          ) : (
            recordDisplay(employee.email)
          )}
        </td>
        <td className={styles.employmentType}>
          {recordDisplay(_.get(employee, 'personnel_history.employment_type_name'))}
        </td>
        {hasBranchOffice && (
          <td className={styles.applicableOffice}>{recordDisplay(_.get(employee, 'personnel_history.office_name'))}</td>
        )}
        <td className={styles.group}>
          {recordDisplay(
            !_.isEmpty(getGroups(employee)) &&
              getGroups(employee).map((group, index) => (
                <span key={index}>
                  {group}
                  <br />
                </span>
              ))
          )}
        </td>
        <td className={styles.dateColumn}>
          {(employee.joined_at && employee.joined_at.replace(/-/g, '/')) || (
            <ShouldInput onClick={this.handleInputClick} type={WILL_ENROLL_ON} />
          )}
        </td>
      </tr>
    )
  }
}

const getAdditionalParams = (isRequest, isDirectRequest) => ({
  embed: isDirectRequest ? ['personnel_history', 'could_send_input_request', 'user'] : ['personnel_history'],
  ...(isRequest ? {received_request_status_procedure_type: 'enroll', received_request_status_status: 'doing'} : {}),
})

class EnrollEmployeesList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: null,
      employeeId: null,
      showModal: false,
      name: '',
      initialValues: {},
    }
  }

  handleRowClick = (id) => {
    const {isMultiple, toogleSelected, changeSelected} = this.props
    if (isMultiple) {
      toogleSelected(id)
    } else {
      changeSelected(id)
    }
  }

  handleInputClick = (employeeId, type, name, initialValues = {}) => {
    this.setState({
      employeeId,
      type,
      showModal: true,
      name,
      initialValues,
    })
  }

  hideModal = (loadData = false) => {
    this.setState(_.assign({}, this.state, {showModal: false}))
    if (loadData) {
      const {location, fetchEmployees, user} = this.props
      fetchEmployees(getCurrentQueryFromLocation(location, user))
    }
  }

  disabledMessage = (employee, user, isDirectRequest) => {
    const {isRequest} = this.props
    const userEmployeeId = user.employee?.id
    const {id, email, joined_at, could_send_input_request} = employee || {}
    if (isRequest && !email) {
      return 'メールアドレスを入力してください'
    }
    if (!joined_at) {
      return '入社日を入力してください'
    }
    if (isDirectRequest && id === userEmployeeId) {
      return '自分自身に依頼を送ることは出来ません'
    }
    if (isDirectRequest && employee.invitation_status !== 'registered') {
      return '従業員に依頼を送るには、あらかじめ従業員招待をしてマイページを発行してください'
    }
    if (isDirectRequest && !could_send_input_request) {
      return '自己の所属する事業所の管理者に依頼を送ることは出来ません'
    }
    if (isDirectRequest && isProcessing(employee)) {
      return '更新中の情報があります'
    }
    return null
  }

  handleSelectAllClick = () => {
    const {employees, selected, changeMulti, isDirectRequest, user} = this.props
    const allSelected =
      _.some(selected) &&
      employees.every((employee) => selected[employee.id] || this.disabledMessage(employee, user, isDirectRequest))
    changeMulti(
      employees
        .filter((employee) => !this.disabledMessage(employee, user, isDirectRequest))
        .map((employee) => ({[employee.id]: !allSelected}))
    )
  }

  componentWillUnmount() {
    const {isLeaveSelected} = this.props
    if (!isLeaveSelected) {
      this.props.clearSelected()
    }
  }

  render() {
    const {
      user,
      employees,
      isRequest,
      isDirectRequest,
      isMultiple,
      selected,
      location: {pathname},
      hasBranchOffice,
    } = this.props
    const allSelected =
      _.some(selected) &&
      employees.every((employee) => selected[employee.id] || this.disabledMessage(employee, user, isDirectRequest))
    const allClickClass = allSelected ? 'm-table-checkbox-on' : 'm-table-checkbox-off'
    return (
      <div>
        <SearchForm additionalParams={getAdditionalParams(isRequest, isDirectRequest)} />
        <ConnectedInputModal
          type={this.state.type}
          employeeId={this.state.employeeId}
          show={this.state.showModal}
          name={this.state.name}
          hideModal={this.hideModal}
          initialValues={this.state.initialValues}
        />
        <div className="l-overflow-scroll">
          <table className={classNames('m-table-list', styles.directRequestTable)}>
            <thead>
              <tr>
                {(isMultiple && (
                  <th
                    className={classNames(allClickClass, ' m-table-list-check u-cur-pointer', styles.checkBox)}
                    onClick={this.handleSelectAllClick}
                  >
                    <input type="checkbox" readOnly checked={allSelected} />
                  </th>
                )) || <th className="m-table-list-check" />}
                {(isRequest || isDirectRequest) && <th className={styles.requestStatus}>依頼状態</th>}
                <SortableTh field="staff_code" globalClassName={styles.staffcode}>
                  スタッフ
                  <wbr />
                  コード
                </SortableTh>
                <SortableTh field="full_name_kana" globalClassName={styles.nameColumn}>
                  氏名
                </SortableTh>
                <th className={styles.mailAddress}>メールアドレス</th>
                <SortableTh field="employment_type" globalClassName={styles.employmentType}>
                  雇用形態
                </SortableTh>
                {hasBranchOffice && (
                  <SortableTh field="office" globalClassName={styles.applicableOffice}>
                    適用事業所
                  </SortableTh>
                )}
                <SortableTh field="group" globalClassName={styles.group}>
                  グループ
                </SortableTh>
                <SortableTh field="joined_at" globalClassName={styles.dateColumn}>
                  入社日
                </SortableTh>
              </tr>
            </thead>
            <tbody>
              {employees.map((employee) => (
                <Row
                  key={employee.id}
                  onClick={this.handleRowClick}
                  onInputClick={this.handleInputClick}
                  selected={selected[employee.id] || false}
                  type={isMultiple ? 'checkbox' : 'radio'}
                  {...{employee, isRequest, isDirectRequest, hasBranchOffice}}
                  disabledMessage={this.disabledMessage(employee, user, isDirectRequest)}
                />
              ))}
            </tbody>
          </table>
        </div>
        <PaginatorWithResult limit={getSavedDisplayEmployeeLimit(pathname, user)} />
      </div>
    )
  }
}

export default connect(
  (state) => ({
    employees: state.employees.list.data,
    employeeCount: state.employees.list.count,
    token: state.auth.token,
    query: state.employees.query,
    selected: state.employees.selected,
    location: state.router.location,
    user: state.session.currentUser,
  }),
  (dispatch, ownProps) => ({
    onLocationChange(location, search) {
      dispatch(push({...location, search}))
    },
    fetchEmployees(query) {
      dispatch(fetchEmployees(query, getAdditionalParams(ownProps.isRequest)))
    },
    ...bindActionCreators(
      _.pick(actionCreators.employees, ['toogleSelected', 'changeSelected', 'clearSelected', 'changeMulti']),
      dispatch
    ),
  })
)((props) => {
  const hasBranchOffice = useHasBranchOffice()
  return <EnrollEmployeesList {...props} hasBranchOffice={hasBranchOffice} />
})
