import React, {Component} from 'react'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {recordDisplay} from 'utils'
import styles from 'employees/Invite.scss'
import {actionCreators} from 'actions'
import api from 'api'
import Modal from 'jbc-front/components/Modal'
import CommonModal from 'jbc-front/components/CommonModal'
import {getGroups} from 'employees/form/EmploymentInfomation'
import {TextField, CheckboxField} from 'jbc-front/components/Form'
import {email} from 'validators'
import {reduxForm, submit} from 'redux-form'
import {bindActionCreators} from 'redux'
import Button from 'jbc-front/components/Button'
import {getState} from 'utils'
import {notifySuccess, notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import {getCurrentQueryFromLocation, fetchEmployees, getSavedDisplayEmployeeLimit} from 'employees/list/utils'
import PaginatorWithResult from 'employees/list/PaginatorWithResult'
import SearchForm from 'employees/list/SearchForm'
import SortableTh from 'employees/list/SortableTh'
import {Content} from 'components/layout/Content'
import classNames from 'classnames'

const emailFormRender = ({handleSubmit, name}) => (
  <form onSubmit={handleSubmit}>
    <div className="form_box_main">
      <TextField name="email" label={`${name}さんのメールアドレス`} validate={email} />
    </div>
    <div className={styles.emailNotice}>
      メールアドレスを変更する場合、ご利用中のジョブカン他サービスにも自動反映されます。
    </div>
  </form>
)

export class Hint extends Component {
  componentDidMount() {
    document.addEventListener('click', this.handleClick, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, true)
  }

  handleClick = () => {
    this.props.onClick()
  }
  render() {
    return (
      <div className={styles.hint}>
        <div className={styles.hintShape} />
        <div className={styles.hintMessage}>{this.props.children}</div>
      </div>
    )
  }
}

const EmailForm = reduxForm({
  form: 'emailInput',
  enableReinitialize: true,
  onSubmit: async (values, dispatch, {employeeId, hideModal}) => {
    const {
      auth: {token},
    } = await getState(dispatch)
    try {
      await api.createWithAuth(token).employees.update(employeeId, values)
      hideModal(true)
    } catch (err) {
      dispatch(asyncError(err))
    }
  },
})(emailFormRender)

const InviteForm = reduxForm({
  form: 'inviteForm',
  onSubmit: async (values, dispatch, {selected}) => {
    const employeeIds = _.keys(_.pickBy(selected))
    const {
      auth: {token},
    } = await getState(dispatch)
    let allDone = true
    for (let id of employeeIds) {
      try {
        await api.createWithAuth(token).employees.invite(id, values)
        dispatch(actionCreators.employees.toogleSelected(id))
      } catch (err) {
        dispatch(asyncError(err))
        allDone = false
      }
    }
    if (allDone) {
      dispatch(notifySuccess('送信しました'))
      dispatch(push('/employees'))
    } else {
      const state = await getState(dispatch)
      const location = state.router.location
      const user = state.session.currentUser
      dispatch(fetchEmployees(getCurrentQueryFromLocation(location, user), additionalParams))
    }
  },
})(({handleSubmit, hideModal, submitting}) => (
  <React.Fragment>
    <CommonModal.Header hideModal={hideModal}>本人に招待メールを送る</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <div>
          選択した従業員宛に、マイページ発行の招待メールを送信します。
          <br />
          <div className="form_box_main u-mt20">
            <CheckboxField name="send_input_request" label="マイページ発行と同時に従業員情報入力を依頼する" />
          </div>
          【注意】
          <br />・<b>入社の手続き</b>や<b>従業員情報の変更</b>に伴う手続きを行っている場合、
          <br />
          {'　'}情報が上書きされる恐れがありますので情報入力依頼は行わないでください。
          <br />
          ・情報入力依頼を受けた従業員は、初回ログイン時にマイページで従業員情報を入力できます。
          <br />
          {'　'}従業員が入力した情報は管理者が承認することで反映します。
        </div>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <CommonModal.Buttons>
        <Button onClick={hideModal}>キャンセル</Button>
        <Button primary onClick={handleSubmit} disabled={submitting}>
          送信
        </Button>
      </CommonModal.Buttons>
    </CommonModal.Footer>
  </React.Fragment>
))

const InputModal = ({employeeId, show, hideModal, name, initialValues, dispatch}) => (
  <Modal
    isOpen={show}
    onSubmit={() => dispatch(submit('emailInput'))}
    hideModal={hideModal}
    body={<EmailForm employeeId={employeeId} hideModal={hideModal} name={name} initialValues={initialValues} />}
    header="メールアドレスの登録"
    cancel="キャンセル"
    submit="保存"
  />
)

export const invitationStatuses = {
  not_invited: '未招待',
  invited: '招待済み',
  registered: '登録済み',
  only_enrollment_input: '未招待',
}

const ConnectedInputModal = connect()(InputModal)

class Row extends Component {
  state = {
    showHint: false,
    hint: null,
  }
  handleClick = () => {
    const {disabledMessage} = this.props
    if (disabledMessage) {
      this.setState({
        showHint: true,
        hint: disabledMessage,
      })
    } else {
      this.props.onClick(this.props.employee.id)
    }
  }
  handleInputClick = (e) => {
    e.stopPropagation()
    const {employee, onInputClick} = this.props
    onInputClick(employee.id, employee.display_last_name, {email: employee.email})
  }

  hideHint = () => {
    this.setState({
      showHint: false,
    })
  }

  render() {
    const {employee, selected, disabledMessage} = this.props
    const selectedClass = selected ? 'm-table-checkbox-on' : 'm-table-checkbox-off'
    const disabledClass = disabledMessage ? ' u-cur-notallowed' : ' u-cur-pointer'

    return (
      <tr>
        <td onClickCapture={this.handleClick} className={selectedClass + disabledClass}>
          {this.state.showHint && <Hint onClick={this.hideHint}>{this.state.hint}</Hint>}
          <input
            type="checkbox"
            checked={selected}
            className={disabledClass ? 'u-cur-notallowed' : 'u-cur-pointer'}
            readOnly
          />
        </td>
        <td>{recordDisplay(employee.staff_code)}</td>
        <td>{recordDisplay.fullName(employee)}</td>
        <td className="u-break-word" onClick={this.handleInputClick}>
          <a className="u-txt-link">{employee.email || '未入力'}</a>
        </td>
        <td>{recordDisplay(invitationStatuses[employee.invitation_status])}</td>
        <td>
          {recordDisplay(
            !_.isEmpty(getGroups(employee)) &&
              getGroups(employee).map((group, index) => (
                <span key={index}>
                  {group}
                  <br />
                </span>
              ))
          )}
        </td>
        <td>{recordDisplay(employee.joined_at && employee.joined_at.replace(/-/g, '/'))}</td>
      </tr>
    )
  }
}

const selectByAll = (employee) =>
  ['not_invited', 'only_enrollment_input'].includes(employee.invitation_status) && employee.email

const isAllSelected = (employees, selected) =>
  _.some(selected) && employees.every((employee) => selected[employee.id] || !selectByAll(employee))

const additionalParams = {embed: ['personnel_history', 'user']}

class List extends Component {
  componentDidMount() {
    this.props.clearSelected()
  }

  state = {
    type: null,
    employeeId: null,
    showModal: false,
    name: '',
    showConfirmModal: false,
    initialValues: {},
  }

  handleRowClick = (id) => {
    const {toogleSelected} = this.props
    toogleSelected(id)
  }

  handleInputClick = (employeeId, name, initialValues = {}) => {
    this.setState({
      employeeId,
      showModal: true,
      name,
      initialValues,
    })
  }

  hideModal = (loadData = false) => {
    this.setState({showModal: false})
    if (loadData) {
      const {location, fetchEmployees, user} = this.props
      fetchEmployees(getCurrentQueryFromLocation(location, user))
    }
  }

  handleInviteClick = () => {
    const selected = _.keys(_.pickBy(this.props.selected))
    if (selected.length === 0) {
      this.props.notifyNoSelect()
    } else {
      this.setState({showConfirmModal: true})
    }
  }

  hideConfirmModal = () => {
    this.setState({showConfirmModal: false})
  }

  disabledMessage(employee) {
    if (!employee.email) {
      return 'メールアドレスを入力してください'
    } else if (employee.invitation_status === 'registered') {
      return 'すでに登録済みです'
    }
    return null
  }

  handleSelectAllClick = () => {
    const {employees, selected, changeMulti} = this.props
    const allSelected = isAllSelected(employees, selected)
    if (allSelected) {
      changeMulti(employees.filter((employee) => selected[employee.id]).map((employee) => ({[employee.id]: false})))
    } else {
      changeMulti(employees.filter(selectByAll).map((employee) => ({[employee.id]: true})))
    }
  }

  componentWillUnmount() {
    this.props.clearSelected()
  }

  render() {
    const {
      changeMulti,
      user,
      employees,
      selected,
      location: {pathname},
    } = this.props
    const allSelected = isAllSelected(employees, selected)
    const allClickClass = allSelected ? 'm-table-checkbox-on' : 'm-table-checkbox-off'

    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">従業員招待</h1>
          <p className="m-title-main-note">
            従業員に招待メールを送ることで、従業員はマイページが利用できるようになります。詳しい招待方法は
            <a
              className="u-txt-link"
              href="https://jobcan-lms.zendesk.com/hc/ja/articles/115001900171"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
            <br />
            従業員はマイページから情報の変更と各種手続きの申請ができます。詳しい内容は
            <a
              href="https://jobcan-lms.zendesk.com/hc/ja/categories/115000170371"
              className="u-txt-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
          </p>
        </div>
        <Content size="xl-4">
          <SearchForm additionalParams={additionalParams} />

          <div className="l-overflow-scroll">
            <table className={classNames('m-table-list', styles.inviteTable)}>
              <thead>
                <tr>
                  <th
                    onClick={this.handleSelectAllClick}
                    className={allClickClass + ' m-table-list-check u-cur-pointer'}
                  >
                    <input type="checkbox" readOnly checked={allSelected} />
                  </th>
                  <SortableTh field="staff_code" globalClassName={styles.staffcode}>
                    スタッフ
                    <wbr />
                    コード
                  </SortableTh>
                  <SortableTh field="full_name_kana" globalClassName={styles.nameColumn}>
                    氏名
                  </SortableTh>
                  <th className={styles.emailColumn}>メールアドレス</th>
                  <th className={styles.inviteStatusColumn}>招待状況</th>
                  <SortableTh field="group" globalClassName={styles.groupColumn}>
                    グループ
                  </SortableTh>
                  <SortableTh field="joined_at" globalClassName={styles.dateColumn}>
                    入社日
                  </SortableTh>
                </tr>
              </thead>
              <tbody>
                {employees.map((employee) => (
                  <Row
                    key={employee.id}
                    onClick={this.handleRowClick}
                    onInputClick={this.handleInputClick}
                    selected={selected[employee.id] || false}
                    {...{employee}}
                    disabledMessage={this.disabledMessage(employee)}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <PaginatorWithResult limit={getSavedDisplayEmployeeLimit(pathname, user)} />
          <div className="u-mt20 u-ta-c">
            <Button primary onClick={this.handleInviteClick}>
              招待メールを送信
            </Button>
          </div>
        </Content>
        <ConnectedInputModal
          employeeId={this.state.employeeId}
          show={this.state.showModal}
          name={this.state.name}
          hideModal={this.hideModal}
          initialValues={this.state.initialValues}
        />
        <CommonModal isOpen={this.state.showConfirmModal} hideModal={this.hideConfirmModal}>
          <InviteForm selected={selected} hideModal={this.hideConfirmModal} changeMulti={changeMulti} />
        </CommonModal>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    employees: state.employees.list.data,
    count: state.employees.list.count,
    token: state.auth.token,
    query: state.employees.query,
    selected: state.employees.selected,
    location: state.router.location,
    user: state.session.currentUser,
  }),
  (dispatch) => ({
    notifyNoSelect() {
      dispatch(notifyError('従業員が選択されていません'))
    },
    fetchEmployees(query) {
      dispatch(fetchEmployees(query, additionalParams))
    },
    ...bindActionCreators(
      _.pick(actionCreators.employees, ['toogleSelected', 'clearSelected', 'changeMulti']),
      dispatch
    ),
  })
)(List)
