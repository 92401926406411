import {FC, useState, useEffect} from 'react'
import {FloatingContainer} from 'components/layout/FloatingContainer'

import {RemindModal} from './RemindModal'
import {CancelModal} from './CancelModal'
import styles from './NoneOrRejectedButtons.scss'

import Button from 'jbc-front/components/Button'

type FloatingButtonsProps = {
  detailInputRequestId: number
  disabledRemind: boolean
}

const INVALID_INTERVAL = 60

export const NoneOrRejectedButtons: FC<FloatingButtonsProps> = ({detailInputRequestId, disabledRemind}) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)
  const [isRemindModalOpen, setIsRemindModalOpen] = useState<boolean>(false)
  const [endTime, setEndTime] = useState<number | null>(null)
  const [countdown, setCountdown] = useState<number>(0)

  useEffect(() => {
    if (!endTime) return

    const timer = setInterval(() => {
      const remaining = Math.round((endTime - Date.now()) / 1000)
      if (remaining <= 0) {
        clearInterval(timer)
        setCountdown(0)
        setEndTime(null)
      } else {
        setCountdown(remaining)
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [endTime])

  const startCountdown = () => {
    setEndTime(Date.now() + INVALID_INTERVAL * 1000)
  }

  return (
    <>
      <FloatingContainer>
        {disabledRemind && <p className={styles.disabledRemind}>再依頼できる項目が存在しません</p>}
        <Button onClick={() => setIsCancelModalOpen(true)}>取下げ</Button>
        <Button primary onClick={() => setIsRemindModalOpen(true)} disabled={disabledRemind}>
          再送信
        </Button>
      </FloatingContainer>

      <>
        <RemindModal
          id={detailInputRequestId}
          isOpen={isRemindModalOpen}
          onClose={() => setIsRemindModalOpen(false)}
          counter={[countdown, startCountdown]}
        />
        <CancelModal id={detailInputRequestId} isOpen={isCancelModalOpen} onClose={() => setIsCancelModalOpen(false)} />
      </>
    </>
  )
}
